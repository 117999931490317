<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <img src="/image/common/top_title_wood_bg02.png" style="float:left;"/>
              <div class="study-room-book-header">
                <span class="book-code">{{ study_room.content_name }}</span>
                <h3 class="book-title">{{ study_room.content_title }}</h3>
              </div>
              <img src="/image/common/top_title_wood_bg04.png" style="float:left;"/>
            </div>
          </div>
          <div class="studybox_h4_box">
            <h4>Listening & Reading</h4>
          </div>
          <section class="study_myinfo_list_area" style="height:90%;">
            <div class="mypage_scroll_content">
              <section class="study_record_box">
                <!-- content -->
                <div class="record_title_box" style="width: 300px; background: none;">
                  <h4 style="font-size: 2.1em; font-weight: bold; text-decoration: underline;" v-if="wordTest.content.word !== null" v-text="wordTest.content.word"></h4>
                </div>
                <div v-if="!complete">
                  <div style="margin: 10px 0px; padding: 10px; text-align: left;">
                    <div v-for="(option, optionIndex) in wordTest.content.options" :key="`option-${optionIndex}`">
                      <input type="radio" @click="sendUserAnswer(option.type)" :id="`option_${option.type}`" :value="option.type" v-model="wordTest.content.user_answer" style="cursor:pointer;margin-right:5px;vertical-align: top; margin-top: 3px;">
                      <label :for="`option_${option.type}`" v-html="`<b>${option.type}</b>. ${option.label}`" style="cursor:pointer; width: calc(100% - 20px);"></label><br>
                    </div>
                  </div>
                </div>
                <div v-else style="margin-top: -60px;">
                  <img src="/image/common/book-content-completed.png" class="book-content-completed">
                </div>
                <!-- //content -->
              </section>
              <div class="quiz_bottom_btn_box">
                <div class="study_result_quiz_num01 clickable" v-if="back" @click="loadContent(back, talkScheduleId, studyRoomId)">{{ back }}</div>
                <img src="/image/sub/reading_bottom_arr.png" alt="슬래쉬 아이콘" style="vertical-align:middle;margin:0 10px 0 10px;">
                <div class="study_result_quiz_num01 clickable" v-if="next" @click="loadContent(next, talkScheduleId, studyRoomId)">{{ next }}</div>
                <div class="study_result_quiz_num01 clickable" v-else @click="completed" style="width: 105px;border-radius: 10px;font-weight: normal;height: 40px;line-height: 38px;">Completed</div>
              </div>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'study-room.content', params: { talkScheduleId: talkScheduleId } }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import StudyRoom from '@/models/StudyRoom'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  name: 'StudyRoom.WordTest',
  data () {
    return {
      study_room: {},
      displayModal: false,
      complete: false,
      loading: false,
      wordTest: {
        content: {
          word: null,
          options: null,
          user_answer: null,
          answer: null
        }
      },
      pagination: {},
      next: null,
      back: null,
      totalPage: 0,
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      studyRoomId: typeof this.$route.params.studyRoomId !== 'undefined' ? this.$route.params.studyRoomId : '',
      page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
    }
  },
  mounted () {
    this.loadContent(this.page, this.talkScheduleId, this.studyRoomId)
  },
  methods: {
    async loadContent (page, talkScheduleId, studyRoomId) {
      this.loading = true
      const searchParams = {}
      this.page = page
      searchParams.page = page
      this.$router.replace({ query: searchParams })
      const response = await StudyRoom.viewContent({ page: page, talkScheduleId: talkScheduleId, studyRoomId: studyRoomId })
      this.wordTest = response.data.data[0]
      this.pagination = response.data.meta.pagination
      this.next = this.pagination.next
      this.back = this.pagination.back
      this.study_room = response.data.meta.study_room
      this.loading = false
    },
    sendUserAnswer (data) {
      this.wordTest.content.user_answer = data
      StudyRoom.updateContent({ page: this.page, talkScheduleId: this.talkScheduleId, studyRoomId: this.studyRoomId, user_answer: this.wordTest.content.user_answer })
    },
    completed () {
      this.complete = true
      const self = this
      setTimeout(function () {
        console.log(self)
        self.$router.replace({ name: 'study-room.content', params: { talkScheduleId: self.talkScheduleId } })
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
